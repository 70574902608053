export default [
  {
    key: 'betToolId',
    label: 'field.tool',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'tool',
    selectionKey: 'id',
    selectionLabel: 'betToolEn',
    cols: 12,
  },
]
