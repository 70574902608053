<template>
  <b-modal
    id="modal-update-tool"
    cancel-variant="outline-secondary"
    :ok-title="$t('field.update')"
    :cancel-title="$t('button.close')"
    centered
    :title="title"
    @ok="handleOk"
    size="md"
  >
    <validation-observer ref="updateToolForm" #default="{ invalid }">
      <!-- form -->
      <n-form-confirmation
        ref="formConfirmation"
        key="updateToolForm"
        :form="$refs.updateToolForm"
        :disabled="invalid"
        @submit="submit"
        class="define-result-form"
      >
        <n-input :fields="fields" v-model="data" />
      </n-form-confirmation>
    </validation-observer>

    <template #modal-ok>
      <b-spinner small label="Spinning" v-if="loading"></b-spinner>
      <span v-else>{{ $t("field.update") }}</span>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BSpinner,
} from "bootstrap-vue";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import Repository from "@/repositories/RepositoryFactory";
import FormInput from "./formInput";
import moment from "moment";

const MatchRepository = Repository.get("match");

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BSpinner,
    NFormConfirmation,
    NInput,
  },
  data() {
    return {
      title: "",
      loading: false,
      key: 1,
      data: {
        id: null,
        betToolId: null,
      },
    };
  },
  methods: {
    show(item) {
      const date = moment(item.betDate).format("DD-MM-YYYY");
      const startTime = moment(item.startTime, "HH:mm")
        .locale(this.$i18n.locale)
        .format("hh:mm a");
      const endTime = moment(item.endTime, "HH:mm")
        .locale(this.$i18n.locale)
        .format("hh:mm a");
      this.title = `${date} (${startTime} - ${endTime})`;

      this.data = {
        id: item.id,
        betToolId: item.betToolId,
      };
      this.key++;
      this.$bvModal.show("modal-update-tool");
    },
    hide() {
      this.$bvModal.hide("modal-update-tool");
    },
    handleOk(bvModal) {
      bvModal.preventDefault();
      if (this.loading) {
        return;
      }

      this.$refs.updateToolForm.validate().then((success) => {
        if (success) {
          this.$refs.formConfirmation.confirm();
        }
      });
    },
    submit() {
      this.loading = true;
      MatchRepository.update(this.data.id, { betToolId: this.data.betToolId })
        .then((response) => {
          this.hide();
          this.$emit("success");
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.updateToolForm.setErrors(error.response?.data?.errors);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = FormInput;

    return {
      fields,
    };
  },
};
</script>