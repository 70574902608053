export default [
  {
    key: 'increasement',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'betDate',
    label: 'field.date',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'time',
    label: 'field.time',
  },
  {
    key: 'betTool',
    label: 'field.tool',
  },
  {
    key: 'state',
    label: 'field.status',
  },
  {
    key: 'result',
    label: 'field.result',
  },
  {
    key: 'resultDate',
    label: 'field.resultDate',
  },
  {
    key: 'actions',
    label: 'field.actions',
  },
];
