<template>
  <div>
    <load-profile></load-profile>

    <!-- Search Container Card -->
    <n-search-container
      :active="true"
      :show-header="false"
      v-model="params"
      @search="search"
      @reset="reset"
      :loading="loading"
      v-if="searchFields.length"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-0-5">
        <b-row>
          <b-col>
            <label>{{ $t("general.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
        >
          <template #cell(betDate)="data">
            {{ data.item.betDate | formatDate("DD-MM-YYYY") }}
          </template>
          <template #cell(time)="data">
            {{
              moment(data.item.startTime, "HH:mm")
                .locale($i18n.locale)
                .format("hh:mm a")
            }}
            -
            {{
              moment(data.item.endTime, "HH:mm")
                .locale($i18n.locale)
                .format("hh:mm a")
            }}
          </template>
          <template #cell(betTool)="data">
            <b-link
              class="link-underline"
              v-if="data.item.betTool"
              v-b-tooltip.hover
              :title="$t('field.update')"
              @click="updateTool(data.item)"
            >
              {{
                $i18n.locale == "en"
                  ? data.item.betTool.betToolEn
                  : data.item.betTool.betToolKh
              }}
            </b-link>
          </template>
          <template #cell(state)="data">
            <span v-if="data.item.state == 1" class="text-primary">
              {{ $t("field.openBet") }}
            </span>
            <span v-else class="text-danger">
              {{ $t("field.closeBet") }}
            </span>
          </template>
          <template #cell(result)="data">
            <b-link
              v-if="data.item.result == 0"
              class="text-secondary link-underline"
              @click="updateResult(data.item)"
              v-b-tooltip.hover
              :title="$t('field.defineResult')"
            >
              {{ $t("field.cancel") }}
            </b-link>
            <b-link
              v-else-if="data.item.result == 1"
              class="text-rain link-underline"
              @click="updateResult(data.item)"
              v-b-tooltip.hover
              :title="$t('field.defineResult')"
            >
              {{ $t("field.rain") }}
            </b-link>
            <b-link
              v-else-if="data.item.result == 2"
              class="text-norain link-underline"
              @click="updateResult(data.item)"
              v-b-tooltip.hover
              :title="$t('field.defineResult')"
            >
              {{ $t("field.noRain") }}
            </b-link>
            <b-link
              v-else
              class="text-secondary link-underline"
              @click="updateResult(data.item)"
              v-b-tooltip.hover
              :title="$t('field.defineResult')"
            >
              {{ $t("field.defineResult") }}
            </b-link>
          </template>
          <template #cell(resultDate)="data">
            {{ data.item.resultDate | formatDate }}
          </template>
          <template #cell(actions)="data">
            <b-button
              variant="danger"
              size="sm"
              v-if="data.item.isEnable"
              @click="
                confirmUpdateMatchStatus(data.item.id, !data.item.isEnable)
              "
            >
              {{ $t("button.close") }}
            </b-button>
            <b-button
              variant="success"
              size="sm"
              v-else
              @click="
                confirmUpdateMatchStatus(data.item.id, !data.item.isEnable)
              "
            >
              {{ $t("button.open") }}
            </b-button>
          </template>
        </n-table>
        <n-pagination
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
          ref="pagination"
        ></n-pagination>
      </div>
    </b-card>

    <update-result-modal ref="updateResultModal" @success="getData" />
    <update-tool-modal ref="updateToolModal" @success="getData" />
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BModal,
  BRow,
  BLink,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import SearchInputs from "./searchInput";
import UpdateResultModal from "./UpdateResultModal";
import UpdateToolModal from "./UpdateToolModal";
import moment from "moment";
import { calculateDatesByPeriod } from "@/libs/helper";

const MatchRepository = Repository.get("match");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BModal,
    BLink,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    vSelect,
    UpdateResultModal,
    UpdateToolModal,
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
    userData: function () {
      return this.$store.state.profile.name
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem("userData"));
    },
  },
  watch: {
    perPage(value) {
      this.list(1);
    },
    "params.period": function (value) {
      this.calculateDate();
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        startDate: this.$route.query.startDate || moment().format("YYYY-MM-DD"),
        endDate: this.$route.query.endDate || moment().format("YYYY-MM-DD"),
        period: this.$route.query.period || "today",
      },
      moment,
    };
  },
  mounted() {
    this.getData(this.params.page);
  },
  methods: {
    confirmUpdateMatchStatus(id, isEnable) {
      this.$bvModal
        .msgBoxConfirm(this.$t("alert.unableToRevertAfterSave"), {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "md",
          okVariant: isEnable ? "success" : "danger",
          okTitle: isEnable ? this.$t("button.open") : this.$t("button.close"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.updateMatchStatus(id, isEnable);
          }
        });
    },
    updateMatchStatus(id, isEnable) {
      MatchRepository.updateStatus(id, isEnable)
        .then((response) => {
          this.getData();
        })
        .catch(() => {});
    },
    updateQuerySting() {
      this.$router.replace({ query: this.params });
    },
    list(page = 1) {
      this.params.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.updateQuerySting();
      this.getData(this.params.page);
    },
    search(searchText) {
      this.params.search = searchText;
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData(this.params.page);
    },
    reset() {
      this.$refs.search.reset();
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.params.period = "today";
      this.calculateDate();
      this.updateQuerySting();
      this.getData(this.page);
    },
    calculateDate() {
      let dates = calculateDatesByPeriod(this.params.period);
      if (dates.length) {
        this.params.startDate = dates[0];
        this.params.endDate = dates[1];
      }
    },
    getData() {
      this.loading = true;
      MatchRepository.index({
        ...this.params,
        searchFields: this.searchFields,
      })
        .then((response) => {
          let data = response.data.data.list;
          this.items = [...data];
          this.total = response.data.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    updateResult(item) {
      this.$refs.updateResultModal.show(item);
    },
    updateTool(item) {
      this.$refs.updateToolModal.show(item);
    },
  },
  setup() {
    const fields = [...TableFields];
    const searchFields = [...SearchInputs];
    const resource = "bet-setting";
    const route = "tool";

    return {
      fields,
      searchFields,
      resource,
      route,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
